<template>
  <el-form
    ref="form"
    :model="form"
    :rules="!readonly ? rules : null"
    :validate-on-rule-change="false"
  >
    <h5 v-if="!hideLabelType" class="mt-0 mb-4 text-th-color-header">
      {{ types[form.type] }}
    </h5>

    <el-row :gutter="20">
      <!-- Street -->
      <el-col :lg="16" :md="12">
        <el-form-item :label="$t('common.forms.labels.street')" prop="street">
          <el-input
            id="street"
            v-model="form.street"
            data-testid="street"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>

      <!-- Street number -->
      <el-col :lg="8" :md="8">
        <el-form-item
          :label="$t('common.forms.labels.street_number')"
          prop="street_number"
        >
          <el-input
            id="street_number"
            v-model="form.street_number"
            data-testid="street_number"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <!-- City -->
      <el-col :lg="16" :md="12">
        <el-form-item :label="$t('common.forms.labels.city')" prop="locality">
          <el-input
            id="locality"
            v-model="form.locality"
            data-testid="locality"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>

      <!-- Postal code -->
      <el-col :lg="8" :md="8">
        <el-form-item
          :label="$t('common.forms.labels.postal_code')"
          prop="postal_code"
        >
          <el-input
            id="postal_code"
            v-model="form.postal_code"
            data-testid="postal_code"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <!-- Region -->
      <el-col :md="12" :lg="16">
        <el-form-item :label="$t('common.forms.labels.region')" prop="region">
          <el-input
            id="region"
            v-model="form.region"
            data-testid="region"
            :readonly="readonly"
          />
        </el-form-item>
      </el-col>

      <el-col :lg="8" :md="8" class="flex">
        <!-- Country -->
        <el-form-item :label="$t('common.forms.labels.country')" prop="country">
          <country-select
            id="country"
            v-model="form.country"
            data-testid="country"
            :readonly="readonly"
          />
        </el-form-item>

        <!-- Delete -->
        <div v-if="form.type !== 'local' && !hideDeleteButton">
          <el-button
            plain
            icon="Delete"
            :aria-label="
              $t('components.th_addresses_input.buttons.delete.label')
            "
            class="mt-th-input-title ml-4 el-button--text-icon"
            @click="handleDelete"
          />
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import CountrySelect from '@/components/country-select'

export default {
  components: {
    CountrySelect
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    requiredFields: {
      type: Array,
      default: () => []
    },
    hideDeleteButton: {
      type: Boolean,
      default: false
    },
    hideLabelType: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isMainAddressRequired: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      types: {
        local: this.$t('common.forms.labels.addresses.types.local'),
        home: this.$t('common.forms.labels.addresses.types.home'),
        main: this.$t('common.forms.labels.addresses.types.main'),
        default: this.$t('common.forms.labels.addresses.types.main'),
        work: this.$t('common.forms.labels.addresses.types.work'),
        billing: this.$t('common.forms.labels.addresses.types.billing'),
        delivery: this.$t('common.forms.labels.addresses.types.delivery'),
        return: this.$t('common.forms.labels.addresses.types.return')
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    rules() {
      return {
        street: [
          {
            required: this.isRequired('street'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            max: 512,
            message: this.$t('common.forms.rules.max_length', { length: 512 }),
            trigger: ['blur', 'change']
          }
        ],
        street_number: [
          {
            required: this.isRequired('street_number'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            max: 16,
            message: this.$t('common.forms.rules.max_length', { length: 16 })
          }
        ],
        postal_code: [
          {
            required: this.isRequired('postal_code'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            max: 10,
            message: this.$t('common.forms.rules.max_length', { length: 10 })
          }
        ],
        locality: [
          {
            required: this.isRequired('locality'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        country: [
          {
            required: this.isRequired('country'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            validator: this.checkCountryInput,
            message: this.$t(
              'pages.settings.account.edit.rules.country.format'
            ),
            trigger: ['blur', 'change']
          }
        ],
        region: [
          {
            required: this.isRequired('region'),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.form)
      }
    }
  },
  methods: {
    isRequired(type) {
      if (this.form.type === 'local' && this.isMainAddressRequired) {
        return true
      }
      return this.form.type !== 'local' && this.requiredFields.includes(type)
    },
    handleDelete() {
      this.$emit('delete')
    },
    checkCountryInput(rule, value, callback) {
      if (!/^[A-Z]{2}$/.test(value)) {
        callback(new Error())
      } else {
        callback()
      }
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
